import React from "react";
import { graphql } from "gatsby";

import Layout from "../Components/Layout";

import "../styles/article-styles.css";

export default function Template({ data }) { // this prop will be injected by the GraphQL query below.
    const { markdownRemark } = data; // data.markdownRemark holds your post data
    const { frontmatter, html } = markdownRemark;
    return (
        <Layout>
            <div className="blog-post my-6">
                <h1 className="pb-1">{frontmatter.title}</h1>
                <h3 className="mb-8 font-semibold">{frontmatter.date}</h3>
                <div
                    className="blog-post-content"
                    dangerouslySetInnerHTML={{ __html: html }}
                />
            </div>
        </Layout>
    )
}

export const pageQuery = graphql`
  query($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        slug
        title
      }
    }
  }
`